
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import eventBus from "@/core/services/eventBus";
import HelpModal from "@/app/components/modals/HelpModal.vue";

import AssignmentUnit from '@/app/components/AssignmentUnit.vue'

import store from '@/core/store/PersistentStorage'
import assignmentApiClient from '@/app/services/assignmentApiClient'

export default defineComponent({
  name: "assignments",
  components: { AssignmentUnit, HelpModal },
  beforeUnmount() {
    eventBus.$off('openHelp');
  },
  data() {
    return {
      units: undefined
    }
  },
  created() {
    if (store.authentication.user.currentInstance) {
      assignmentApiClient.get(`/students/${store.authentication.user.currentInstance.studentInstanceId}/fusionUnits`).then(response => {
        this.units = response.data.units
      });
    }
    else {
      this.units = null
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted() {
    setCurrentPageTitle("My Assignments");
    eventBus.$on('openHelp', () => {
      this.$refs.helpModal.openDialog();
    });
  }
});
