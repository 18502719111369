import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "assignment-unit__img" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "assignment-unit__body p-4" }
const _hoisted_4 = { class: "unit-header" }
const _hoisted_5 = { class: "unit-label font-semibold inline text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'unit', params: { id: $props.unit.id} },
    class: "assignment-unit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: $props.unit.coverImage,
          alt: ""
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString($props.unit.title), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}